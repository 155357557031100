<template>
  <step-activity-layout class="final-test" :show-bottom="false" :activity-finished="isGameFinished" :activity-started="isGameStarted">
    <template v-slot:head>
      <p class="head-label">{{ $t('training.finalTest.label') }}</p>
      <p class="head-title">{{ $t('training.finalTest.title') }}</p>
    </template>
    <template v-slot:intro>
      <activity-intro v-if="!isMoocValid"
        @start="isGameStarted = true"
        :title="$t('training.finalTest.title')"
      >
        <template v-slot:label>{{ $t('training.finalTest.label') }}</template>
        <template v-slot:rules>
          <div class="rules">
            <p v-html="$t('training.finalTest.intro.rules')"></p>
          </div>
        </template>
        <template v-slot:buttonLabel>
          {{ $t('training.finalTest.intro.goToActivity') }}
        </template>
      </activity-intro>
      <div v-else class="test-finished-wrapper">
        <div class="app-simple-block">
          <p class="title">
            {{ $t('training.finalTest.testAlreadyPassed') }}
          </p>
          <app-button-layout @click="redirectToOscar">
            {{ $t('global.navigation.backToDashboard') }}
          </app-button-layout>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div  v-if="!isGameFinished" class="quizz-wrapper" ref="quizz">
        <quizz-activity
          class="final-quizz"
          :shuffle-answers="true"
          :is-multiple="true"
          :questions="questions"
          @quizz-finished="quizzFinished"
          @question-index-updated="questionUpdated"
          :enable-correction="false"
        >
          <template v-slot:top="{ context, questionIndex, questionsCount }">
            <div class="quizz-top">
              <p class="title">{{ context }}</p>
              <p class="page-index">
                {{ questionIndex }} / {{ questionsCount }}
              </p>
            </div>
          </template>
          <template v-slot:question="{ question, rightAnswer }">
            <div class="question-wrapper">
              <p class="question" v-html="question"></p>
              <p class="nb-answer-annotation">{{ rightAnswer.length > 1 ? $t('training.finalTest.multipleAnswer') : $t('training.finalTest.singleAnswer') }}</p>
            </div>
          </template>
          <template v-slot:answer="{ label, isRight, isChecked, showAnswers }">
            <p class="answer-wrapper" :class="{ right: isRight, checked: isChecked, 'show-answers': showAnswers }" v-html="label"></p>
          </template>
          <template v-slot:correction="{ correction }">
            <p class="correction" v-html="correction"></p>
          </template>
        </quizz-activity>
      </div>
      <activity-result-block v-else :success="isSuccess" @replay-activity="replayActivity" :is-custom-content="true">
        <template v-slot:title>
          {{ $t('training.finalTest.resultBlock.title') }}
        </template>
        <template v-slot:content>
          <div class="result-top" :class="{ success: isSuccess }">
            <p class="result-label">{{ isSuccess ? $t('training.finalTest.resultBlock.successLabel') : $t('training.finalTest.resultBlock.failLabel') }}</p>
            <p class="result" v-html="isSuccess ? $t('training.finalTest.resultBlock.success') : $t('training.finalTest.resultBlock.fail')"></p>
          </div>
          <div class="result-content">
            <img class="icon" :src="require(`@/assets/icons/smileys/${scoreActivity}.svg`)" :alt="scoreActivity">
            <div class="score-info">
              {{ errorsCount }} {{ $tc('training.finalTest.resultBlock.errors', errorsCount, { count: errorsCount }) }}
            </div>
          </div>
        </template>
        <template v-slot:buttons>
          <app-button-layout v-if="!isSuccess" @click="retry">
            {{ $t('training.finalTest.resultBlock.retryButton') }}
          </app-button-layout>
          <app-button-layout color="alternate-secondary" @click="redirectToOscar">
            {{ $t('training.finalTest.resultBlock.userHomeButton') }}
          </app-button-layout>
        </template>
      </activity-result-block>
    </template>
  </step-activity-layout>
</template>

<script>
import StepActivityLayout from '@/components/training/StepActivityLayout'
import ActivityResultBlock from '@/components/training/ActivityResultBlock'
import QuizzActivity from '@/components/training/QuizzActivity'
import ActivityIntro from '@/components/training/ActivityIntro'
import USER_LEVELS from '@/constants/USER_LEVELS'
import { computed, onBeforeUnmount } from 'vue'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import { useStore } from 'vuex'
import redirectToOscar from '@/utils/redirectToOscar'
import useTrainingUtils from '@/utils/useTrainingUtils'
import { questionsVersionA, questionsVersionB } from '@/data/finalTestQuestions'

export default {
  name: 'finalTest',
  components: { StepActivityLayout, QuizzActivity, ActivityIntro, ActivityResultBlock, AppButtonLayout },
  setup () {
    const { saveProgressError } = useTrainingUtils()
    const store = useStore()
    const isGameFinished = computed({
      get () {
        return store.state.training.finalTestDatas?.isGameFinished
      },
      set (value) {
        store.commit('training/setFinalTestData', { key: 'isGameFinished', value: value })
      }
    })
    const isGameStarted = computed({
      get () {
        return store.state.training.finalTestDatas?.isGameStarted
      },
      set (value) {
        store.commit('training/setFinalTestData', { key: 'isGameStarted', value: value })
      }
    })
    const scoreActivity = computed({
      get () {
        return store.state.training.finalTestDatas?.scoreActivity
      },
      set (value) {
        store.commit('training/setFinalTestData', { key: 'scoreActivity', value: value })
      }
    })
    const errorsCount = computed({
      get () {
        return store.state.training.finalTestDatas?.errorsCount
      },
      set (value) {
        store.commit('training/setFinalTestData', { key: 'errorsCount', value: value })
      }
    })
    const replayActivity = () => {
      isGameFinished.value = false
      isGameStarted.value = false
    }

    const isMoocValid = computed(() => {
      return store.state.training.isMoocValid
    })

    // set game started to false to display intro if quizz exited before finish
    onBeforeUnmount(() => {
      if (isGameStarted.value && !isGameFinished.value) {
        isGameStarted.value = false
      }
    })

    return { isGameFinished, isGameStarted, scoreActivity, errorsCount, replayActivity, redirectToOscar, saveProgressError, isMoocValid }
  },
  data () {
    return {
      isVersionBSelected: false,
      questionsVersionA: questionsVersionA,
      questionsVersionB: questionsVersionB
    }
  },
  computed: {
    isSuccess () {
      return this.scoreActivity === USER_LEVELS.GOOD
    },
    questions () {
      return this.isVersionBSelected ? questionsVersionB : questionsVersionA
    }
  },
  methods: {
    getUserLevelFromScore (score) {
      return score > 20
        ? USER_LEVELS.GOOD
        : USER_LEVELS.MEDIUM
    },
    async quizzFinished ({ score }) {
      this.isGameFinished = true
      this.errorsCount = this.questions.length - score
      this.scoreActivity = this.getUserLevelFromScore(score)
      this.changeQuestionsVersion()
      try {
        await this.$store.dispatch('training/recordFinalTest', {
          isSuccess: this.isSuccess,
          score: score
        })
      } catch (error) {
        this.saveProgressError(error)
      }
    },
    questionUpdated () {
      const quizzHeadY = window.document.querySelector('.final-test.step-activity-layout .head')
        .getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({ top: quizzHeadY, behavior: 'smooth' })
    },
    retry () {
      this.isGameFinished = false
      this.isGameStarted = false
      this.scoreActivity = ''
    },
    changeQuestionsVersion () {
      this.isVersionBSelected = !this.isVersionBSelected
    }
  }
}
</script>

<style lang="scss" scoped>
.test-finished-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .app-simple-block {
    margin-top: $space-xl;
    .title {
      color: $c-secondary;
    }
  }
}
.training-warning {
  margin-top: $space-s;
  color: $c-text;
}
.head-label {
  color: $c-primary;
  font-family: $ff;
  font-size: $fz-s;
  font-weight: $fw-l;
  text-transform: uppercase;
  margin-bottom: $space-xs;
  padding: $space-s $space-sm;
  border-radius: $radius;
  background-color: #fff;
  display: inline-block;
}
.head-title {
  color: #fff;
  font-family: $ff;
  font-size: $fz-ml;
  font-weight: $fw-l;
}
.quizz-top {
  font-size: $fz-xs;
  font-weight: $fw-l;
  text-align: center;
  .page-index {
    display: inline-block;
    color: #fff;
    background-color: $c-text-lighter;
    border-radius: $radius;
    padding: 3px $space-s;
  }
}
.question-wrapper {
  margin-top: $space-sm;
  color: $c-text-dark;
  font-size: $fz-l;
  text-align: center;
  font-weight: $fw-m;
  margin-bottom: $space-m;
  .question {
    margin-top: $space-m;
    font-weight: 700;
  }
  .nb-answer-annotation {
    font-style: italic;
    font-size: $fz-s;
    margin-top: $space-xs;
  }
}
.answer-wrapper {
  text-align: center;
  color: $c-text;
  padding: $space-xs $space-m;
  min-height: 60px;
  font-weight: $fw-m;
  display: flex;
  align-items: center;
  justify-content: center;
  &.show-answers {
    &.right {
      color: $c-primary;
      font-weight: $fw-l;
      animation-name: win;
      animation-duration: 1.2s;
    }
    &:not(.checked).right {
      color: $c-primary;
    }
  }
}
.result-top {
  text-align: center;
  padding: $space-s;
  .result-label {
    color: #fff;
    font-size: $fz-l;
    font-weight: $fw-l;
    display: inline-block;
    background-color: #f5a001;
    border-radius: $radius-xl;
    padding: $space-s $space-sm;
  }
  .result {
    margin-top: $space-m;
    font-weight: $fw-m;
  }
  &.success {
    .result-label {
      background-color: $c-primary;
    }
  }
}
.result-content {
  background-color: rgba($c-secondary, 0.11);
  border-radius: $radius;
  margin-top: $space-m;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-m $space-xl;
  .score-info {
    color: $c-text-dark;
    font-size: $fz-s;
    font-weight: $fw-l;
  }
  .icon {
    width: 60px;
  }
}
.app-button-layout {
  width: 100%;
  & + .app-button-layout {
    margin-top: $space-s;
  }
}
</style>

<style lang="scss">
.quizz-activity.final-quizz {
  .quizz-wrapper {
    .answers {
      display: flex;
      flex-direction: column;
      .answer {
        height: auto;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .final-test.step-activity-layout.activity-finished {
    .content-wrapper {
      grid-template-columns: repeat(10, 1fr);
      .content {
        grid-column-start: 4;
        grid-column-end: 8;
      }
    }
  }
}
</style>
