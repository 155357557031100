import i18n from '@/i18n'

export const questionsVersionA = [
  {
    name: 'question1',
    question: i18n.global.t('training.finalTest.questions.question1.question'),
    rightAnswer: ['a', 'b', 'c', 'd'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question1.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question1.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question1.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question1.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question1.answers.answer5')
      }
    ]
  },
  {
    name: 'question2',
    question: i18n.global.t('training.finalTest.questions.question2.question'),
    rightAnswer: ['a', 'b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question2.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question2.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question2.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question2.answers.answer4')
      }
    ]
  },
  {
    name: 'question3',
    question: i18n.global.t('training.finalTest.questions.question3.question'),
    rightAnswer: ['g'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer6')
      },
      {
        name: 'g',
        label: i18n.global.t('training.finalTest.questions.question3.answers.answer7')
      }
    ]
  },
  {
    name: 'question4',
    question: i18n.global.t('training.finalTest.questions.question4.question'),
    rightAnswer: ['a', 'b', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question4.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question4.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question4.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question4.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question4.answers.answer5')
      }
    ]
  },
  {
    name: 'question6',
    question: i18n.global.t('training.finalTest.questions.question6.question'),
    rightAnswer: ['a', 'b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question6.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question6.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question6.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question6.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question6.answers.answer5')
      }
    ]
  },
  {
    name: 'question7',
    question: i18n.global.t('training.finalTest.questions.question7.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question7.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question7.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question7.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question7.answers.answer4')
      }
    ]
  },
  {
    name: 'question8',
    question: i18n.global.t('training.finalTest.questions.question8.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question8.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question8.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question8.answers.answer3')
      }
    ]
  },
  {
    name: 'question9',
    question: i18n.global.t('training.finalTest.questions.question9.question'),
    rightAnswer: ['c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question9.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question9.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question9.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question9.answers.answer4')
      }
    ]
  },
  {
    name: 'question10',
    question: i18n.global.t('training.finalTest.questions.question10.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question10.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question10.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question10.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question10.answers.answer4')
      }
    ]
  },
  {
    name: 'question11',
    question: i18n.global.t('training.finalTest.questions.question11.question'),
    rightAnswer: ['a', 'b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question11.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question11.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question11.answers.answer3')
      }
    ]
  },
  {
    name: 'question12',
    question: i18n.global.t('training.finalTest.questions.question12.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question12.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question12.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question12.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question12.answers.answer4')
      }
    ]
  },
  {
    name: 'question13',
    question: i18n.global.t('training.finalTest.questions.question13.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question13.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question13.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question13.answers.answer3')
      }
    ]
  },
  {
    name: 'question14',
    question: i18n.global.t('training.finalTest.questions.question14.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question14.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question14.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question14.answers.answer3')
      }
    ]
  },
  {
    name: 'question15',
    question: i18n.global.t('training.finalTest.questions.question15.question'),
    rightAnswer: ['a', 'b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question15.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question15.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question15.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question15.answers.answer4')
      }
    ]
  },
  {
    name: 'question16',
    question: i18n.global.t('training.finalTest.questions.question16.question'),
    rightAnswer: ['a', 'b', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question16.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question16.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question16.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question16.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question16.answers.answer5')
      }
    ]
  },
  {
    name: 'question17',
    question: i18n.global.t('training.finalTest.questions.question17.question'),
    rightAnswer: ['a', 'b', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question17.answers.answer6')
      }
    ]
  },
  {
    name: 'question18',
    question: i18n.global.t('training.finalTest.questions.question18.question'),
    rightAnswer: ['b', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer6')
      },
      {
        name: 'g',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer7')
      },
      {
        name: 'h',
        label: i18n.global.t('training.finalTest.questions.question18.answers.answer8')
      }
    ]
  },
  {
    name: 'question19',
    question: i18n.global.t('training.finalTest.questions.question19.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question19.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question19.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question19.answers.answer3')
      }
    ]
  },
  {
    name: 'question20',
    question: i18n.global.t('training.finalTest.questions.question20.question'),
    rightAnswer: ['b', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question20.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question20.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question20.answers.answer3')
      }
    ]
  },
  {
    name: 'question21',
    question: i18n.global.t('training.finalTest.questions.question21.question'),
    rightAnswer: ['b', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question21.answers.answer6')
      }
    ]
  },
  {
    name: 'question22',
    question: i18n.global.t('training.finalTest.questions.question22.question'),
    rightAnswer: ['a', 'b', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question22.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question22.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question22.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question22.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question22.answers.answer5')
      }
    ]
  },
  {
    name: 'question23',
    question: i18n.global.t('training.finalTest.questions.question23.question'),
    rightAnswer: ['c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question23.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question23.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question23.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question23.answers.answer4')
      }
    ]
  },
  {
    name: 'question24',
    question: i18n.global.t('training.finalTest.questions.question24.question'),
    rightAnswer: ['a', 'b', 'd'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question24.answers.answer6')
      }
    ]
  },
  {
    name: 'questionTypeAidesMpr',
    question: i18n.global.t('training.finalTest.questions.questionTypeAidesMpr.question'),
    rightAnswer: ['a', 'b', 'd'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.questionTypeAidesMpr.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.questionTypeAidesMpr.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.questionTypeAidesMpr.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.questionTypeAidesMpr.answers.answer4')
      }
    ]
  },
  {
    name: 'question25',
    question: i18n.global.t('training.finalTest.questions.question25.question'),
    rightAnswer: ['b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question25.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question25.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question25.answers.answer3')
      }
    ]
  },
  {
    name: 'questionSaut4Classe',
    question: i18n.global.t('training.finalTest.questions.questionSaut4Classe.question'),
    rightAnswer: ['c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.questionSaut4Classe.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.questionSaut4Classe.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.questionSaut4Classe.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.questionSaut4Classe.answers.answer4')
      }
    ]
  },
  {
    name: 'question26',
    question: i18n.global.t('training.finalTest.questions.question26.question'),
    rightAnswer: ['b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question26.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question26.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question26.answers.answer3')
      }
    ]
  },
  {
    name: 'question27',
    question: i18n.global.t('training.finalTest.questions.question27.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question27.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question27.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question27.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question27.answers.answer4')
      }
    ]
  },
  {
    name: 'questionMontantMar',
    question: i18n.global.t('training.finalTest.questions.questionMontantMar.question'),
    rightAnswer: ['a', 'b', 'c', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.questionMontantMar.answers.answer6')
      }
    ]
  },
  {
    name: 'question30',
    question: i18n.global.t('training.finalTest.questions.question30.question'),
    rightAnswer: ['d'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question30.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question30.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question30.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question30.answers.answer4')
      }
    ]
  }
]
export const questionsVersionB = [
  {
    name: 'question1B',
    question: i18n.global.t('training.finalTest.questions.question1B.question'),
    rightAnswer: ['a', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question1B.answers.answer6')
      }
    ]
  },
  {
    name: 'question2B',
    question: i18n.global.t('training.finalTest.questions.question2B.question'),
    rightAnswer: ['d', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question2B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question2B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question2B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question2B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question2B.answers.answer5')
      }
    ]
  },
  {
    name: 'question3B',
    question: i18n.global.t('training.finalTest.questions.question3B.question'),
    rightAnswer: ['a', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question3B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question3B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question3B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question3B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question3B.answers.answer5')
      }
    ]
  },
  {
    name: 'question4B',
    question: i18n.global.t('training.finalTest.questions.question4B.question'),
    rightAnswer: ['a', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question4B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question4B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question4B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question4B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question4B.answers.answer5')
      }
    ]
  },
  {
    name: 'question5B',
    question: i18n.global.t('training.finalTest.questions.question5B.question'),
    rightAnswer: ['a', 'b', 'd', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question5B.answers.answer6')
      }
    ]
  },
  {
    name: 'question6B',
    question: i18n.global.t('training.finalTest.questions.question6B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question6B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question6B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question6B.answers.answer3')
      }
    ]
  },
  {
    name: 'question7B',
    question: i18n.global.t('training.finalTest.questions.question7B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question7B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question7B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question7B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question7B.answers.answer4')
      }
    ]
  },
  {
    name: 'question8B',
    question: i18n.global.t('training.finalTest.questions.question8B.question'),
    rightAnswer: ['b', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question8B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question8B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question8B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question8B.answers.answer4')
      }
    ]
  },
  {
    name: 'question9B',
    question: i18n.global.t('training.finalTest.questions.question9B.question'),
    rightAnswer: ['b', 'c', 'd'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question9B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question9B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question9B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question9B.answers.answer4')
      }
    ]
  },
  {
    name: 'question10B',
    question: i18n.global.t('training.finalTest.questions.question10B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question10B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question10B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question10B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question10B.answers.answer4')
      }
    ]
  },
  {
    name: 'question11B',
    question: i18n.global.t('training.finalTest.questions.question11B.question'),
    rightAnswer: ['d'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question11B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question11B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question11B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question11B.answers.answer4')
      }
    ]
  },
  {
    name: 'question12B',
    question: i18n.global.t('training.finalTest.questions.question12B.question'),
    rightAnswer: ['c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question12B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question12B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question12B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question12B.answers.answer4')
      }
    ]
  },
  {
    name: 'question13B',
    question: i18n.global.t('training.finalTest.questions.question13B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question13B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question13B.answers.answer2')
      }
    ]
  },
  {
    name: 'question14B',
    question: i18n.global.t('training.finalTest.questions.question14B.question'),
    rightAnswer: ['a', 'b', 'c', 'd', 'e', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question14B.answers.answer6')
      }
    ]
  },
  {
    name: 'question15B',
    question: i18n.global.t('training.finalTest.questions.question15B.question'),
    rightAnswer: ['a', 'b', 'c', 'd', 'e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question15B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question15B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question15B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question15B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question15B.answers.answer5')
      }
    ]
  },
  {
    name: 'question16B',
    question: i18n.global.t('training.finalTest.questions.question16B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question16B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question16B.answers.answer2')
      }
    ]
  },
  {
    name: 'question17B',
    question: i18n.global.t('training.finalTest.questions.question17B.question'),
    rightAnswer: ['a', 'b'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question17B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question17B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question17B.answers.answer3')
      }
    ]
  },
  {
    name: 'question18B',
    question: i18n.global.t('training.finalTest.questions.question18B.question'),
    rightAnswer: ['a', 'c', 'd', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question18B.answers.answer6')
      }
    ]
  },
  {
    name: 'question19B',
    question: i18n.global.t('training.finalTest.questions.question19B.question'),
    rightAnswer: ['a', 'b', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question19B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question19B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question19B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question19B.answers.answer4')
      }
    ]
  },
  {
    name: 'question20B',
    question: i18n.global.t('training.finalTest.questions.question20B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question20B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question20B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question20B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question20B.answers.answer4')
      }
    ]
  },
  {
    name: 'question21B',
    question: i18n.global.t('training.finalTest.questions.question21B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question21B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question21B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question21B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question21B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question21B.answers.answer5')
      }
    ]
  },
  {
    name: 'question22B',
    question: i18n.global.t('training.finalTest.questions.question22B.question'),
    rightAnswer: ['a', 'b', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question22B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question22B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question22B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question22B.answers.answer4')
      }
    ]
  },
  {
    name: 'question23B',
    question: i18n.global.t('training.finalTest.questions.question23B.question'),
    rightAnswer: ['a', 'c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question23B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question23B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question23B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question23B.answers.answer4')
      }
    ]
  },
  {
    name: 'question24B',
    question: i18n.global.t('training.finalTest.questions.question24B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question24B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question24B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question24B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question24B.answers.answer4')
      }
    ]
  },
  {
    name: 'question25B',
    question: i18n.global.t('training.finalTest.questions.question25B.question'),
    rightAnswer: ['e'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question25B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question25B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question25B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question25B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question25B.answers.answer5')
      }
    ]
  },
  {
    name: 'question26B',
    question: i18n.global.t('training.finalTest.questions.question26B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question26B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question26B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question26B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question26B.answers.answer4')
      }
    ]
  },
  {
    name: 'question27B',
    question: i18n.global.t('training.finalTest.questions.question27B.question'),
    rightAnswer: ['a', 'b', 'd', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer6')
      },
      {
        name: 'g',
        label: i18n.global.t('training.finalTest.questions.question27B.answers.answer7')
      }
    ]
  },
  {
    name: 'question28B',
    question: i18n.global.t('training.finalTest.questions.question28B.question'),
    rightAnswer: ['c'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question28B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question28B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question28B.answers.answer3')
      }
    ]
  },
  {
    name: 'question29B',
    question: i18n.global.t('training.finalTest.questions.question29B.question'),
    rightAnswer: ['a'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question29B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question29B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question29B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question29B.answers.answer4')
      }
    ]
  },
  {
    name: 'question30B',
    question: i18n.global.t('training.finalTest.questions.question30B.question'),
    rightAnswer: ['b', 'c', 'e', 'f'],
    answers: [
      {
        name: 'a',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer1')
      },
      {
        name: 'b',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer2')
      },
      {
        name: 'c',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer3')
      },
      {
        name: 'd',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer4')
      },
      {
        name: 'e',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer5')
      },
      {
        name: 'f',
        label: i18n.global.t('training.finalTest.questions.question30B.answers.answer6')
      }
    ]
  }
]
